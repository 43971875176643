import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import VisibilitySensor from "react-visibility-sensor";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

const options = {
    items:1,
    loop:false,
    autoplay:false,
    nav:true,
    responsiveClass:true,
    dots:false,
    autoplayHoverPause:true,
    mouseDrag:true,
    navText: [
        "<i class='fa fa-angle-left'></i>",
        "<i class='fa fa-angle-right'></i>"
    ]
}
 
class MainBanner extends React.Component {
    render(){
        return (
            <OwlCarousel 
                id="home"
                className="home-slides owl-theme"
                {...options}
            >
            {this.props.slideData.map((data, idx) => (
            

                <div id="home"  className={` main-banner main-banner2 item-bg-one  ${data.klasName}`} key={idx}>
                <div className=""></div>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <VisibilitySensor>
                                        {({ isVisible }) => (
                                            <div className="main-banner-text">
                                                <h4 
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne" : ''
                                                    }
                                                >
                                                    {data.heading}
                                                </h4>
                                                <h1 
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne" : ''
                                                    }
                                                    dangerouslySetInnerHTML={{ __html: data.subHeading }}
                                                />

                                                <p 
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne" : ''
                                                    }
                                                >
                                                    {data.text}
                                                </p>
                                                <Link 
                                                    to="/kontakt" 
                                                    className={`
                                                        btn btn-primary ${isVisible ? "animated fadeInDown slow opacityOne" : ""}
                                                    `}
                                                    
                                                    
                                                >
                                                    Boka en tid
                                                </Link>
                                                <Link
                                                    to="/kontakt" 
                                                    className={`
                                                        btn btn-primary view-work ${isVisible ? "animated fadeInDown slow opacityOne" : ""}
                                                    `}
                                                >
                                                    Kontakta oss
                                                </Link>
                                                <br></br>   <br></br>
                                        


                        <Link className="btn btn-primary btn-secondary animated fadeInDown slow opacityOne btn-primaryColor btn-primaryColor2" to="/kampanjer">Kolla våra Kampanjer</Link> 

                                                
                                            </div>
                                            
                                        )}
                                        </VisibilitySensor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </OwlCarousel>
        );
    }
}

MainBanner.defaultProps = {
    slideData: [
        {
            heading: "Välkommen till vår hemsida!",
            subHeading: "Vi älskar <span>jobbet</span> ni hatar",
            text: "Gör som dina grannar- anlita oss för att upptäcka skillnaden med professionell städhjälp",
            klasName: "item-bg-two"
        },
       ,
    ]
}
 
export default MainBanner;