import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/vecko.jpg';
import imgTwo from '../../assets/images/stor.jpg';
import imgThree from '../../assets/images/windows2.jpg';
import imgFour from '../../assets/images/different-img4.jpg';

class WhyWeDifferent extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel-x");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render() {
        return (
            <section className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Våra <span>städtjänster</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                                {/* <!-- Tab 1 --> */}
                                <label
                                    onClick={(e) => this.openTabSection(e, 'stad')}
                                    className="tablinks checked"
                                    name="tabset1"
                                    id="tab1"
                                    aria-controls="stad"
                                    htmlFor="tab1"
                                >
                                    Veckostädning
                                </label>
                                {/* <!-- Tab 2 --> */}
                                <label
                                    onClick={(e) => this.openTabSection(e, 'storstad')}
                                    className="tablinks"
                                    name="tabset2"
                                    id="tab2"
                                    aria-controls="starstad"
                                    htmlFor="tab2"
                                >
                                    Storstädning/Engångsstädning
                                </label>
                                {/* <!-- Tab 3 --> */}
                                <label
                                    onClick={(e) => this.openTabSection(e, 'windows')}
                                    className="tablinks"
                                    name="tabset3"
                                    id="tab3"
                                    aria-controls="windows"
                                    htmlFor="tab3"
                                >
                                    Fönsterputs
                                </label>
                             


                                <div className="tab-panels">
                                    <section id="stad" className="tab-panel-x" style={{ display: "block" }}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Veckostädning</h4>
                                                    <p>Ett hem behöver städas regelbundet och därför rekommenderar vi dig att boka städabonnemang. Då kommer vi och städar ditt hem varje, varannan eller var fjärde vecka. Vårt veckostädning är en populär tjänst som är mycket uppskattad av våra kunder.

                                                        Börja med att fylla i ett kontaktformulär. Vi återkommer med information om hur lång tid det beräknas ta att städa ditt hem veckovis. Undrar du vad som ingår i vår städning? Då ska du klicka här!</p>
                                                    <br></br>
                                                    <h4>Prislista</h4>

                                                    <ul>
                                                        <li><i className="fa fa-dot-circle-o"></i>Måndag – tisdag 189 kr/timme*</li>
                                                        <li><i className="fa fa-dot-circle-o"></i>Onsdag 199 kr/timme*</li>
                                                        <li><i className="fa fa-dot-circle-o"></i>Torsdag – fredag 209 kr/timme*</li>
                                                        <li><i className="fa fa-dot-circle-o"></i>Städning var 4:e vecka – 219 kr/timme* oavsett veckodag</li>
                                                    </ul>
                                                    <p>*Alla priser är angivna efter RUT-avdrag. Om din bostad ligger mer än 15 km från T-centralen så tar vi ut en inställelseavgift på 75 kr. </p>

                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgOne} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="storstad" className="tab-panel-x">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Storstädning eller engångsstädning</h4>
                                                    <p>Behöver ditt hem en noggrann och grundlig städning? Då ska du boka vår storstädning. Då städas det vitt och brett, på höjden, utvändigt och invändigt. För att se vilka städmoment som ingår klicka här.

                                                        Om du inte är i behov av en rejäl storstädning, då kan du istället boka ett engångsuppdrag av oss.</p><br></br>
                                                    <h4>Prislista</h4>
                                                    <ul>
                                                        <li><i className="fa fa-dot-circle-o"></i>Storstädning/engångsuppdrag 249 kr/timme.*</li>

                                                    </ul>

                                               <p>*Alla priser är angivna efter RUT-avdrag. Om din bostad ligger mer än 15 km från T-centralen så tar vi ut en inställelseavgift på 75 kr. </p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgTwo} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    <section id="windows" className="tab-panel-x">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Fönsterputs</h4>
                                                    <p>Vill du ha skinande rena fönster? Låt våra professionella fönsterputsare komma hem till dig och putsa fönstren. De tar med sig sin egen utrustning och priset baseras på hur många fönster du har och dess modell. Kontakta oss för offert.</p>
                                            <br></br>

                                                    <Link to="/kontakt" className="btn btn-primary">Kontakta oss</Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgThree} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhyWeDifferent;