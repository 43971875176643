import React from 'react'
import BannerTwo from '../PageBanners/BannerTwo';
import Strategy from '../Common/Strategy';
import WhyWeDifferent3 from '../Common/WhyWeDifferentC';
import FaqServices from '../Common/FaqServices';
import FaqServicesTwo from '../Common/FaqServicesTwo';
import Price from '../Common/Price';
import Subscribe from '../Common/Subscribe';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';

class HomeTwo extends React.Component {
    render() {
        return (
            <>
                {/* Main Banner */}
                <BannerTwo />


                {/* Städtjänster*/}
                <WhyWeDifferent3 />

          




                {/* Subscribe Area */}
                <Subscribe />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />


            </>
        );
    }
}

export default HomeTwo;