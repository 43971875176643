import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/vecko.jpg';
import imgTwo from '../../assets/images/stor.jpg';
import imgThree from '../../assets/images/windows2.jpg';
import imgFour from '../../assets/images/different-img4.jpg';

class WhyWeDifferent extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel-x");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render() {
        return (
            <section className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Våra <span>städtjänster</span></h2>
                        <p>Det är svårt att hinna med. Vi hjälper er lösa livets pussel & hålla det rent hemma. Vi hjälper dig med städning och en enklare vardag</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                       
                         
                                {/* <!-- Tab 3 --> */}
                        
                             


                                <div className="tab-panels">
                                   

                                  

                                    <section id="windows" className="tab-panel-x" style={{ display: "block" }}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Fönsterputs</h4>
                                                    <p>Vill du ha skinande rena fönster? Låt våra professionella fönsterputsare komma hem till dig och putsa fönstren. De tar med sig sin egen utrustning och priset baseras på hur många fönster du har och dess modell. Kontakta oss för offert.</p>
                                            <br></br>

                                                    <Link to="/kontakt" className="btn btn-primary">Kontakta oss</Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgThree} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhyWeDifferent;