import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
 
const Ctr = () => {
    return (
        <section className="ctr-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section-title mb-0">
                        
                            <h2>Nya Kampanjer</h2>
                            <p>Vi kommer uppdatera med nya erbjudanden varje månad, allt för att du ska kunna göra en riktigt bra deal!.</p>
                            <a class="btn btn-primary" href="/kampanjer">Kolla våra nya kampanjer </a>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
 
export default Ctr;