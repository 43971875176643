import React from 'react'
import MainBanner from '../PageBanners/MainBannerTwo';
import Welcome from '../Common/Welcome';
import About from '../Common/About';
import Strategy from '../Common/Strategy';
import Ctr from '../Common/Ctr';
import WhyWeDifferent from '../Common/WhyWeDifferent';
import Price from '../Common/Price';
import Faq from '../Common/Faq';
import Testimonial from '../Common/Testimonial';
import Subscribe from '../Common/Subscribe';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
 
class HomeTwo extends React.Component {
    render(){
        return (
            <>
                {/* Main Banner */}
                <MainBanner />

                {/* Welcome Area 
                <Welcome />*/}

                {/* About Area */}
                <About />

         
                {/* Who We Are Area 
                <WhoWeAre /> */}

                {/* CTR Area */}
                <Ctr />

                {/* How We Work 
                <HowWeWork /> */}

                {/* Services Area 
                <Services />*/}

           


    

                {/* Testimonials Area */}
                <Testimonial />

                {/* Blog Area */}
               {/* <Blog /> */}


                {/* Subscribe Area */}
                <Subscribe />


                
                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />

      
            </>
        );
    }
}
 
export default HomeTwo;