import React from 'react'
import Faq from '../Common/Faq';
import BannerTwo from '../PageBanners/BannerTwo';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';

class HomeTwo extends React.Component {
    render() {
        return (
            <>

                {/* Main Banner */}
                <BannerTwo />

                {/* Contact Area */}
                <Contact />
                {/* FAQ Area */}
                <Faq />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />


            </>
        );
    }
}

export default HomeTwo;