import React from 'react'
import BannerTwo from '../PageBanners/BannerTwo';
import Strategy from '../Common/Strategy';
import WhyWeDifferent1 from '../Common/WhyWeDifferentA';
import FaqServices from '../Common/FaqServices';
import FaqServicesTwo from '../Common/FaqServicesTwo';
import Price from '../Common/Price';
import Subscribe from '../Common/Subscribe';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';

class HomeTwo extends React.Component {
    render() {
        return (
            <>
                {/* Main Banner */}
                <BannerTwo />


                {/* Städtjänster*/}
                <WhyWeDifferent1 />

                           {/* FAQ Area 1 */}
                           <FaqServices />

                {/* Strategy Area */}
                <Strategy />


     

       

                {/* Price Area 
                <Price />*/}



                {/* Subscribe Area */}
                <Subscribe />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />


            </>
        );
    }
}

export default HomeTwo;