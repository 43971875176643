import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
 
class Faq extends React.Component {
    render(){
        return (
            <section className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Vanliga frågor – <span>FAQ</span></h2>
                        <p>Här har vi samlat svaren på de vanligaste frågorna</p>
                    </div>
                
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Anges städpriset efter RUT-avdraget?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Ja, alla våra priser inkluderar RUT-avdraget och moms.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Hur fungerar RUT-avdraget?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Vi skickar ut en faktura för städningens kostnad där RUT-avdrag är applicerat. När fakturan är betald så anmäler vi beloppet till Skatteverket som sedan skickar ut ett besked till dig som inkluderar månadens och årets totala RUT-avdrag. På så sätt kan du enkelt se till att du inte går över det tillåtna RUT-avdraget. Sedan den 1 januari 2021 kan du dra av upp till 75 000 kronor genom RUT-avdraget i din inkomstdeklaration. När det är dags att deklarera är alla RUT-uppgifter förtryckta i deklarationen.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            När sker städningen om jag köper ett städabonnemang?

                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Om du köper ett abonnemang så sker städningen samma dag och tid vid varje städtillfälle.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Får vi samma städare varje gång?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Ja, om du köper ett städabonnemang så skickar vi samma städare varje gång. Om städaren är sjuk eller ledig så kommer det istället en vikarie.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Hur går jag tillväga för att boka in en städning?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Du bokar in en städning på vår hemsidas framsida. Välj det datum som passar dig bäst och sedan skickar vi en mejlbekräftelse med instruktioner. 
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>


                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Hur långt innan måste jag boka ett städtillfälle?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Vi rekommenderar dig att boka minst 3 arbetsdagar innan städtillfället. Om du har bokat en städning med kort varsel, det vill säga senast 2 arbetsdagar innan städtillfället, då kan du inte göra en avbokning utan debitering. 
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Vad ingår i er städning?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Vi följer en färdig städlista så att både du som kund och våra städare vet vilka delar som ska utföras. Du finner städlistan här. Om du har några egna önskemål så kan vi givetvis anpassa vår städning. Ange i så fall dina önskemål i samband med bokningen. 
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Måste jag förbereda något inför städtillfället?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            För att våra städare ska kunna sätta igång och jobba direkt så ber vi dig att plocka iordning lösa prylar runt om i hemmet. Hit räknar vi till exempel kläder, leksaker och disk. Det är också viktigt att du har rätt städmaterial och städprodukter hemma. Du hittar listan på vad ska du ha hemma här. Vi ber dig även att stanna i ca 10 minuter så att du och städaren kan gå igenom vad som ska utföras.                                             </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Kan jag avboka eller ändra ett städtillfälle?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Ja, det är möjligt. Kontakta din kontaktperson för ändringar i bokningen. Vid sen avbokning mindre än 24 timmar debiteras du 100% av priset.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Har ert städabonnemang någon bindningstid?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Våra städabonnemang har uppsägningstid men ingen bindningstid.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Hur betalar jag för städningen?                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Vi fakturerar all städning månadsvis och i efterskott. Fakturan har 30 dagars betalningstid. Du kan välja mellan tre olika betalningssätt:
		Faktura via e-mejl (PDF – gratis)
		Faktura via post (25 kr)
		Swish (5kr)                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Använder ni er av erfaren städpersonal?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Ja, all vår personal har gott om erfarenhet av städning hemma hos privatkunder. Vi ger de dessutom en internutbildning i allt från städteknik till materialkunskap så att de är väl förberedda.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Vad ska jag göra om något har gått sönder?                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Om du upptäcker att något har gått sönder efter ett städtillfälle så ber vi dig att kontakta oss inom 48 timmar. Vi har en ansvarsförsäkring som täcker de eventuella skador som vår personal vållar när de städar.                                             </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Vilket material används vid städningen?                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Allt städmaterial tillhandahålls av dig som kund. På så sätt kan du känna dig trygg med tanken att vi bara använder sådana städprodukter och redskap som du vill ska användas i ditt hem. Vi har en materiallista med allt du behöver ha hemma vid varje städtillfälle. Den hittar du här.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Vad ska jag göra om jag är missnöjd med städningen?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Om du känner dig missnöjd med städningen så ber vi dig anmäla detta till din kontaktperson senast 24 timmar efter att städningen har utförts. Bifoga gärna en bild på de delar du är missnöjd med. Sedan återkommer vi inom 24 timmar (vardagar).
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                            Hur hanterar ni nycklar och larm vid städningen?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Vid det första städtillfället ber vi dig att möta upp vår städare för att släppa in oss. När vi går kan vi sedan lägga nyckeln i brevlådan eller behålla den. Om du blir abonnemangskund så är det smidigast för alla om du ger oss en egen uppsättning nycklar. Vi ger dig en skriftlig kvittering för nycklarna. Dessutom får de en märkning så att vår personal kan spåra dem.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        
                        <div className="col-lg-5 col-md-12">
                            <div className="image"></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Faq;