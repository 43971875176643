import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import imgOne from '../../assets/images/logo-white-s2.png';

class Navigation extends React.Component {
    state = {
        collapsed: true,
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {


        let elementId = document.getElementById("navbar");


        window.addEventListener("scroll", () => {


            if (window.scrollY) {


                elementId.classList.add("is-sticky")

                // window.history.pushState("", document.title, window.location.pathname);


            } else {
                elementId.classList.remove("is-sticky");
            }
        });







        {/* 

        document.addEventListener("scroll", () => {
            if (window.scrollY = 170) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);

    

            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);


*/}









        if (!(window.location.pathname === '/blog' || window.location.pathname === '/blog-details' || window.location.pathname === '/' || window.location.pathname === '/kontakt' || window.location.pathname === '/' || window.location.pathname === '/om-oss' || window.location.pathname === '/tjanster' || window.location.pathname === '/storstadning' || window.location.pathname === '/veckostadning' || window.location.pathname === '/fonsterputs')) {
            let mainNavLinks = document.querySelectorAll("nav ul li a");

            window.addEventListener("scroll", () => {
                let fromTop = window.scrollY;

                mainNavLinks.forEach(link => {
                    let section = document.querySelector(link.hash);


                });
            });
        }
    }

    goToId = (e) => {
        window.location.hash = e;
        window.location.refresh(true);
    }

    renderMenus = () => {
        if (window.location.pathname === '/blog' || window.location.pathname === '/blog-details') {
            return (
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <AnchorLink onClick={this.toggleNavbar} offset={() => 85} className="nav-link" href="#blog">Blog</AnchorLink>
                    </li>
                </ul>
            );
        }

        return (

            <div className={`top-bar2 ${this.state.activeClass}`}>
                <ul className="navbar-nav ms-auto"
                    onClick={this.toggleNavbar}

                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Hem</Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/kampanjer">Kampanjer</Link> </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/tjanster">Tjänster</Link> </li>


                    <li className="nav-item">
                        <Link className="nav-link" to="/om-oss">Om Oss</Link>                </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/kontakt">Kontakt</Link>
                    </li>
                </ul>
            </div>
        );
    }

    render() {
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'navbar-collapse collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        return (
            <nav id="navbar" className="navbar navbar-expand-md navbar-light bg-light header-sticky">
                <div className="container">
                    <a
                        className="navbar-brand"
                        href="/"
                    >


                        <img src={imgOne} alt="img" height="auto" width="auto" />
                    </a>

                    <button
                        onClick={this.toggleNavbar}
                        className={classTwo}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={classOne} id="navbarSupportedContent">
                        {this.renderMenus()}
                    </div>
                </div>
            </nav>
        );
    }
}

export default withRouter(Navigation);