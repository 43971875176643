import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/clean34.png';
 
class AboutC extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Våra <span> kampanjer</span></h2>
                        <p> Här lägger vi ut de heta kampanjer och erbjudanden som vi erbjuder till våra kunder. Alla erbjudanden är tidsbegränsade så hitta din favorit idag innan det är försent! </p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2> Prova oss nu bestäm dig senare. ❤️ </h2>
                                   
                                    <p></p>
                                    <hr></hr>
                               
                                  <p>  
                                    
                                  Prova hemstädning med 50% rabatt utan att teckna ett abonnemang* (gäller första städtillfället endast en gång per hushåll). Provstädningen är alltid på minst 4 timmar och vi utför då endast städtjänsten. Sedan går det både att ha från 2,5 timme och våra tilläggstjänster vid återkommande städning.
*Rabatten gäller priset för engångsstädning.
                                    
                                     <br></br> <br></br>*Kampanj gäller ej vecka 51-1<br></br><br></br> 

                                     <p>Genom att boka en provstädning så accepterar du våra allmänna villkor. </p><br></br>
                                  <a class="
                                                        btn btn-primary animated fadeInDown slow opacityOne
                                                    " href="/kontakt" previewlistener="true">Kontakta oss för mer info.</a>  </p>




                                </div>


                             
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                                <div className="video-btn">
                                    <Link 
                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                        to="#" 
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo 
                                        channel='youtube' 
                                        isOpen={this.state.isOpen} 
                                        videoId='bk7McNUjWgw' 
                                        onClose={() => this.setState({isOpen: false})} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutC;