import React from 'react';
import { Link } from 'react-router-dom';
 
class Strategy extends React.Component {
    render(){
        return (
            <section className="strategy-area">
                <div className="container-fluid p-0">
                    <div className="row ">
                        <div className="col-lg-6 col-md-5">
                            <div className="image"></div>
                        </div>
                        
                        <div className="col-lg-6 col-md-7">
                            <div className="about-strategy ptb-80">
                                <div className="section-title">
                                    <h4></h4>
                                    <h2>Städprodukter <span>att ha hemma</span></h2>
                                    <p>För att våra städare ska kunna utföra en noggrann och hygienisk städning är det viktigt att du har rätt städmaterial och städprodukter hemma. Vi rekommenderar att du har följande produkter hemma vid varje städtillfälle.</p>
                                </div>
                                
                                <ul>
                                    <li><i className="fa fa-check"></i>Dammsugare</li>
                                    <li><i className="fa fa-check"></i>Dammsugarpåsar</li>
                                    <li><i className="fa fa-check"></i>Skurhink</li>
                                    <li><i className="fa fa-check"></i>Skurmopp</li>
                                    <li><i className="fa fa-check"></i>Dammtrasor (gärna micofiber)</li>
                                    <li><i className="fa fa-check"></i>Torr tygtrasa eller hushållspapper</li>
                                    <li><i className="fa fa-check"></i>Scotch-Brite-svampar med vit yta</li>
                                    <li><i className="fa fa-check"></i>Diskmedel</li>
                                    <li><i className="fa fa-check"></i>Fönsterputs för alla speglar</li>
                                    <li><i className="fa fa-check"></i>Vim eller Jif</li>
                                    <li><i className="fa fa-check"></i>Allrengöringsmedel, t.ex. Ajax</li>
                                    <li><i className="fa fa-check"></i>Toalettrengöringsmedel, t.ex. WC-anka</li>
                                </ul>
                                
                                <Link to="/kontakt" className="btn btn-primary">Boka redan idag</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Strategy;