import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/andrea.jpg';
import imgTwo from '../../assets/images/stor.jpg';
import imgThree from '../../assets/images/windows2.jpg';
import imgFour from '../../assets/images/different-img4.jpg';

class WhyWeDifferent extends React.Component {

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab-panel-x");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "block";
        }

        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" checked", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += " checked";
    }

    render() {
        return (
            <section className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Våra <span>städtjänster</span></h2>
                        <p>Det är svårt att hinna med. Vi hjälper er lösa livets pussel & hålla det rent hemma. Vi hjälper dig med städning och en enklare vardag</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                                {/* <!-- Tab 1 --> */}
                   
                           
                             


                                <div className="tab-panels">
                                    <section id="stad" className="tab-panel-x" style={{ display: "block" }}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Veckostädning</h4>
                                                    <p>Ett hem behöver städas regelbundet och därför rekommenderar vi dig att boka städabonnemang. Då kommer vi och städar ditt hem varje, varannan eller var fjärde vecka. Vårt veckostädning är en populär tjänst som är mycket uppskattad av våra kunder.

                                                        Börja med att fylla i ett kontaktformulär. Vi återkommer med information om hur lång tid det beräknas ta att städa ditt hem veckovis. Undrar du vad som ingår i vår städning? Då ska du klicka här!</p>
                                                    <br></br>
                                                    <h4>Prislista</h4>

                                                    <ul>
                                                        <li><i className="fa fa-dot-circle-o"></i>Veckostädning från 209 kr/timme*</li>

                                                    </ul>
                                                    <p>*Alla priser är angivna efter RUT-avdrag. Om din bostad ligger mer än 15 km från T-centralen så tar vi ut en inställelseavgift på 75 kr. </p>
 
 <br></br><Link to="/kontakt" className="btn btn-primary">Kontakta oss</Link>

                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img"><br></br><br></br>
                                                    <img src={imgOne} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>


                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhyWeDifferent;