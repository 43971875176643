import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/about-2.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Om  <span> Oss</span></h2>
                        <p> Vår personal är vår viktigaste tillgång! Vi ser därför till att ta väl hand om vår personal. Vi hyr inte in extern personal, utan vi anställer alltid vår egen personal. Under provanställningen får alla nyanställda genomgå vår internutbildning med teori och praktik. Därefter övergår provanställningen till en vidareanställning. Alla våra anställda har en ansvarsförsäkring. Molnfria Hem betalar alla skatter och försäkringar för sina anställda. </p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2> Vi har en låg personalomsättning, vilket är ett bevis på att vår personal trivs.</h2>
                               
                                  <p>  Du som kund får alltid hem samma städare när det är dags för din regelbundna städning. Enda undantaget sker om städaren är ledig, sjuk eller på semester. Då kommer istället en av våra duktiga vikarier. Vi upplever att våra fasta städare både ger ett effektivare utfört arbete och en ökad trygghet hos våra kunder.
</p>
                                </div>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i> Välutbildad personal – låg personalomsättning </li>
                        
                                </ul>

                              

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>F-skatt och policy för hantering av cookies och personuppgifter</li>
                                 
                                </ul>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i> En ansvarsförsäkring med ett försäkringsbelopp på upp till 10 miljoner kronor</li>
                                 
                                </ul>

                                <ul className="pull-left">
                                    <li><i className="fa fa-check"></i>Nöjd kund garanti på 48 timmar</li>
                                 
                                </ul>

                             
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                                <div className="video-btn">
                                    <Link 
                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                        to="#" 
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo 
                                        channel='youtube' 
                                        isOpen={this.state.isOpen} 
                                        videoId='bk7McNUjWgw' 
                                        onClose={() => this.setState({isOpen: false})} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;