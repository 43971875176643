import React from 'react'
import BannerTwo from '../PageBanners/BannerTwo';
import Welcome from '../Common/Welcome';
import About from '../Common/About2';
import Strategy from '../Common/Strategy';
import WhyWeDifferent from '../Common/WhyWeDifferent';
import Testimonial from '../Common/Testimonial';
import Subscribe from '../Common/Subscribe';
import Contact from '../Common/Contact';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
 
class HomeTwo extends React.Component {
    render(){
        return (
            <>
                {/* Main Banner */}
                <BannerTwo />

      

                {/* About Area */}
                <About />




                {/* Testimonials Area */}
                <Testimonial />

            


                {/* Subscribe Area */}
                <Subscribe />

        
                
                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />

      
            </>
        );
    }
}
 
export default HomeTwo;