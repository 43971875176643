import '../src/assets/css/bootstrap.min.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import '../src/assets/css/font-awesome.min.css';
import '../src/assets/css/animate.css';
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';

import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navigation from './components/Navigation/Navigation';
import Preloader from './components/Common/Preloader';
import HomeTwo from '../src/components/Pages/HomeTwo';
import Contact from '../src/components/Pages/Contact';
import ContactS from './components/Pages/Success';
import About from '../src/components/Pages/About';
import Blog from './components/Pages/Blog';
import BlogDetails from './components/Pages/BlogDetails';
import Services from './components/Pages/Services';
import Bigcleaning from './components/Pages/Bigcleaning';
import Cleaning from './components/Pages/Cleaning';
import Windows from './components/Pages/Windows';
import Campain from './components/Pages/Campain';

class App extends React.Component {

    state = {
        loading: false
    };

    componentDidMount(){
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }

    hashLinkScroll = () => {
        const { hash } = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    }

    render() {
        return (
            <Router onUpdate={this.hashLinkScroll}>
                <>
                    {this.state.loading ? <Preloader /> : ''}
                    <Navigation />
                    <Route path="/" exact component={HomeTwo} />
                    <Route path="/kampanjer" exact component={Campain} />
                    <Route path="/kontakt" exact component={Contact} />
                    <Route path="/om-oss" exact component={About} />
                    <Route path="/tjanster" exact component={Services} />
                    <Route path="/veckostadning" exact component={Cleaning} />
                    <Route path="/storstadning" exact component={Bigcleaning} />
                    <Route path="/fonsterputs" exact component={Windows} />
                    <Route path="/kontakt2" exact component={ContactS} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog-details" exact component={BlogDetails} />
                </>
            </Router>
        );
    }
}

export default App;
