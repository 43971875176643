import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/andrea.jpg';
import imgTwo from '../../assets/images/grab.jpg';
import imgThree from '../../assets/images/windows2.jpg';
import imgFour from '../../assets/images/different-img4.jpg';

class WhyWeDifferent extends React.Component {



    render() {
        return (
            <section className="why-we-different ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Våra <span>städtjänster</span></h2>
                        <p>Det är svårt att hinna med. Vi hjälper er lösa livets pussel & hålla det rent hemma. Vi hjälper dig med städning och en enklare vardag</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="tabset">
                               
                                {/* <!-- Tab 2 --> */}
    
                               
                             


                                <div className="tab-panels">
                                 

                                    <section id="storstad" className="tab-panel-x" style={{ display: "block" }}>
                               
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-text">
                                                    <h4>Storstädning eller engångsstädning</h4>
                                                    <p>Behöver ditt hem en noggrann och grundlig städning? Då ska du boka vår storstädning. Då städas det vitt och brett, på höjden, utvändigt och invändigt. För att se vilka städmoment som ingår klicka här.

                                                        Om du inte är i behov av en rejäl storstädning, då kan du istället boka ett engångsuppdrag av oss.</p><br></br>
                                                    <h4>Prislista</h4>
                                                    <ul>
                                                        <li><i className="fa fa-dot-circle-o"></i>Storstädning/engångsuppdrag 249 kr/timme.*</li>

                                                    </ul>

                                               <p>*Alla priser är angivna efter RUT-avdrag. Om din bostad ligger mer än 15 km från T-centralen så tar vi ut en inställelseavgift på 75 kr. </p>
                                                </div>

                                                <br></br><Link to="/kontakt" className="btn btn-primary">Kontakta oss</Link>
                                            </div>
<br></br><br></br>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="why-we-different-img">
                                                    <img src={imgTwo} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>

                               

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WhyWeDifferent;