import React from 'react'
import WhyWeDifferent from '../Common/WhyWeDifferent';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';

class HomeTwo extends React.Component {
    render() {
        return (
            <>
        
                {/* Städtjänster*/}
                <WhyWeDifferent />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />


            </>
        );
    }
}

export default HomeTwo;