import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ReactTypingEffect from 'react-typing-effect';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  
class BannerTwo extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-two-2">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h4>Vi på molnfria hem kan hjälpa till dig med  </h4>
                                        <h1>
                                            <ReactTypingEffect
                                                text={[' Veckostädning.', ' Storstädning.', ' Fönsterputs.']}
                                                speed={200}
                                                eraseDelay={100}
                                            />
                                        </h1>
                                        <p>Gör som dina grannar- anlita oss för att upptäcka skillnaden med professionell städhjälp</p>
                                        <Link to="/kontakt" className="btn btn-primary">Boka redan idag</Link>
                                        <Link to="/om-oss" className="btn btn-primary view-work">Vilka är vi ? </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default BannerTwo;