import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/mama1.jpg';
import imgTwo from '../../assets/images/mama2.jpg';
import imgTre from '../../assets/images/mama4.jpg';
class Welcome extends React.Component {
    render() {
        return (
            <section id="welcome" className="welcome-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h4>Städning i Stockholm och dess närområde</h4>
                        <h2>Vill du kunna tillbringa mer tid   <span>med familj och vänner?</span></h2>
                        <p> Då kan du anlita oss för hemstädning! Vår vision är att kunna erbjuda alla hushåll i Stockholm hemstädning till ett rimligt pris. Dessutom erbjuder vi dig givetvis ett pris med RUT-avdrag! Vi håller tät kontakt med dig som kund för att säkerställa att alla är nöjda.
                            Vi gör hemstädning enkelt, smidigt och lättillgängligt. Våra duktiga städare har lång erfarenhet och arbetar både snabbt och effektivt. På så sätt kan vi erbjuda dig ett lågt pris.
                            Om du behöver hjälp med städning i Stockholm, då är du välkommen att kontakta oss!
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6">

                        <a className="navbar-brand" href="/veckostadning">  <img src={imgOne} alt="img" height="auto" width="auto" /></a>

                            <div className="single-box">
                                <h3>Veckostädning</h3>
                                
                                <p></p>





                                <Link to="/veckostadning" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                        <a className="navbar-brand" href="/storstadning">  <img src={imgTwo} alt="img" height="auto" width="auto" /></a>

                            <div className="single-box">
                                <h3>Storstädning</h3>
                                <p></p>
                                <Link to="/storstadning" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <a className="navbar-brand" href="/tjanster">  <img src={imgTre} alt="img" height="auto" width="auto" /></a>

                            <div className="single-box">
                                <h3>Fönsterputs</h3>
                                <p></p>
                                <Link to="/fonsterputs" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Welcome;