import React from 'react';
import clientImgOne from '../../assets/images/client-avatar1.jpg';
import clientImgTwo from '../../assets/images/client-avatar2.jpg';
import clientImgThree from '../../assets/images/client-avatar3.jpg';
import clientImgOneW from '../../assets/images/1woman.png';
import clientImgTwoW from '../../assets/images/2woman.png';
import clientImgThreeW from '../../assets/images/3woman.png';
 
class Testimonial extends React.Component {
    render(){
        return (
            <section className="testimonials-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Våra kunder<span> berättar</span></h2>
                        <p> Våra kunders åsikter är mycket viktiga för oss. Här kan du läsa några av dem.</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgOne} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Jonas Ström</h4>
                                        <h5>Kund // Östermalm</h5>
                                    </div>
                                </div>

                                <p>Molnfria hem levt upp till våra förväntningar på ett mycket bra sätt! Inget att klaga på. Fint och rent och bra kommunikation.</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgTwoW} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Isabella Stewczyk</h4>
                                        <h5>Kund // Hammarbyhöjden</h5>
                                    </div>
                                </div>

                                <p>Trevliga personer med trevligt bemötande och gjorde ett mycket bra jobb. Allt var toppen från början till slut. Mycket bra kommunikation och information.
Man kändes sig trygg helt enkelt.</p>

                                <span><i className="fa fa-quote-right"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgThreeW} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Olivia Hising</h4>
                                        <h5>Kund // Södermalm</h5>
                                    </div>
                                </div>

                                <p>Dom gjorde bra jobb jag är jättenöjd med deras jobb skulle rekommendera dom som städ firma.</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgOneW} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Amanda Ek</h4>
                                        <h5>Skanstull</h5>
                                    </div>
                                </div>

                                <p>Beställde fönsterputs av molnfria hem. De svarade snabbt och det var ingen väntetid.
Resultatet blev bra och personalen som kom var trevlig.</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgTwo} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Sergiej Czerko</h4>
                                        <h5>Stureby</h5>
                                    </div>
                                </div>

                                <p>Jag och min fru valde molnfria hem för hemstädning och är väldigt nöjda. Två stycken väldigt kompetenta och trevliga tjejer hjälpte oss med städningen. Kan varmt rekommendera Molnfria hem! <br></br>
Mvh mycket nöjd kund</p>

                                <span><i className="fa fa-quote-right"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feedback">
                                <div className="client-info">
                                    <div className="client-pic">
                                        <img src={clientImgThree} alt="client-avatar" />
                                    </div>

                                    <div className="client-title">
                                        <h4>Patrik Sauer</h4>
                                        <h5>Kund // Östermalm</h5>
                                    </div>
                                </div>

                                <p>Snabba svar och bra resultat. Bra och trevlig kontakt som fungerat i alla steg.
Utmärkt städning där man gjort det där lilla extra.</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Testimonial;