import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

class FaqServices extends React.Component {
    render() {
        return (
            <section className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Detta ingår i vår  – <span>veckostädning</span></h2>
                        <p>Detaljerad lista</p>
                    </div>

                    <div className="row">

                    <div className="col-lg-5 col-md-12">
                            <div className="image"></div>
                        </div>
                        
                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Köket
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Avtorkning av kakel ovanför diskbänken</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av köksluckor</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av diskho och kranar</li>
                                                        <li><i className="fa fa-check"></i>Tömning av sophink</li>
                                                        <li><i className="fa fa-check"></i>Städning av det skåp där sophinken förvaras</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Damning/avtorkning av öppna ytor, t.ex. köksbord, köksbänkar, hyllor, eluttag </li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av köksfläkten</li><br></br>
                                                        <p>Kan utföras vid önskemål:</p><br></br>
                                                        <li><i className="fa fa-check"></i>älla in all disk i diskmaskinen</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av micro (inuti) </li>
                                                        <li><i className="fa fa-check"></i>Rengöring av filtret i köksfläkten (ej kolfilter)</li>
                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Badrummet
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Rengöring av kakel runt toalettstol, handfat och dusch/badkar</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av duschkabin och badkar (badkaret plockas inte isär)</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av handfatet – även under</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av kranar, duschhållare och nåbara rör</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Utvändig dammsugning/avtorkning av badrumsmöbler </li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av nåbara lampor</li>
                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Vardagsrum

                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Dammtorkning/avtorkning av alla öppna ytor, t.ex. bord, byrå, bokhyllor, eluttag</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av soffor och fåtöljer - ovanpå och under dynorna</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av mattor</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av nåbara tavlor och lampor</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li><br></br>
                                                        <p>Kan utföras vid önskemål:</p><br></br>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av TV</li>

                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Sovrum
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Dammtorkning/avtorkning av alla öppna ytor, t.ex. nattduksbord, byrå, bokhyllor, eluttag</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av mattor</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>
                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Alla andra rum
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Dammtorkning/avtorkning av alla öppna ytor, t.ex. bord, byrå, bokhyllor, eluttag</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av soffor och fåtöljer - ovanpå och under dynorna</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av mattor</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>

                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>


                                </Accordion>
                            </div>
                        </div>

                      
                    </div>
                </div>
            </section>
        );
    }
}

export default FaqServices;