import React from 'react';
import { Link } from 'react-router-dom';

class Price extends React.Component {
    render() {
        return (
            <section id="price" className="price-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Products <span>Pricing</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable">
                                <h3 className="title">Small</h3>
                                <h6>Städning var fjärde vecka</h6>
                                <div className="price-value">

                                    <span className="amount">219.00kr</span><br></br>
                                    <span>Pris för en timme <br></br> oavsett
                                        veckodag.</span>
                                </div>
                                <ul className="pricing-content">
                                <li><i className="fa fa-clock-o"></i> 40 kvm tar ca 3h</li>
                                    <li><i className="fa fa-clock-o"></i> 60 kvm tar ca 3,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 80 kvm tar ca 4h</li>
                                    <li><i className="fa fa-clock-o"></i> 130 kvm tar ca 4,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 160 kvm tar ca 5h</li>
                                    <li><i className="fa fa-clock-o"></i> 229 kvm tar ca 5,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 260 kvm tar ca 6h</li>
                                    <li><i className="fa fa-clock-o"></i> 300 kvm tar ca 7h</li>
                                    <li><i className="fa fa-clock-o"></i> 350 kvm tar ca 8h</li>
                                </ul>
                                <Link to="#" className="btn btn-primary">Beställa nu</Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable">
                                <h3 className="title">Medium</h3>
                                <h6>Städning varannan vecka</h6>
                                <div className="price-value">

                                    <span className="amount">219.00kr</span><br></br>
                                    <span>Pris för en timme <br></br> oavsett
                                        veckodag.</span>
                                </div>
                                <ul className="pricing-content">
                                <li><i className="fa fa-clock-o"></i> 40 kvm tar ca 3h</li>
                                    <li><i className="fa fa-clock-o"></i> 60 kvm tar ca 3,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 80 kvm tar ca 4h</li>
                                    <li><i className="fa fa-clock-o"></i> 130 kvm tar ca 4,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 160 kvm tar ca 5h</li>
                                    <li><i className="fa fa-clock-o"></i> 229 kvm tar ca 5,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 260 kvm tar ca 6h</li>
                                    <li><i className="fa fa-clock-o"></i> 300 kvm tar ca 7h</li>
                                    <li><i className="fa fa-clock-o"></i> 350 kvm tar ca 8h</li>
                                </ul>
                                <Link to="#" className="btn btn-primary">Beställa nu</Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable">
                                <h3 className="title">Small</h3>
                                <h6>Städning var fjärde vecka</h6>
                                <div className="price-value">

                                    <span className="amount">219.00kr</span><br></br>
                                    <span>Pris för en timme <br></br> oavsett
                                        veckodag.</span>
                                </div>
                                <ul className="pricing-content">
                                <li><i className="fa fa-clock-o"></i> 40 kvm tar ca 3h</li>
                                    <li><i className="fa fa-clock-o"></i> 60 kvm tar ca 3,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 80 kvm tar ca 4h</li>
                                    <li><i className="fa fa-clock-o"></i> 130 kvm tar ca 4,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 160 kvm tar ca 5h</li>
                                    <li><i className="fa fa-clock-o"></i> 229 kvm tar ca 5,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 260 kvm tar ca 6h</li>
                                    <li><i className="fa fa-clock-o"></i> 300 kvm tar ca 7h</li>
                                    <li><i className="fa fa-clock-o"></i> 350 kvm tar ca 8h</li>
                                </ul>
                                <Link to="#" className="btn btn-primary">Beställa nu</Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable">
                                <h3 className="title">Small</h3>
                                <h6>Städning var fjärde vecka</h6>
                                <div className="price-value">

                                    <span className="amount">219.00kr</span><br></br>
                                    <span>Pris för en timme <br></br> oavsett
                                        veckodag.</span>
                                </div>
                                <ul className="pricing-content">
                                <li><i className="fa fa-clock-o"></i> 40 kvm tar ca 3h</li>
                                    <li><i className="fa fa-clock-o"></i> 60 kvm tar ca 3,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 80 kvm tar ca 4h</li>
                                    <li><i className="fa fa-clock-o"></i> 130 kvm tar ca 4,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 160 kvm tar ca 5h</li>
                                    <li><i className="fa fa-clock-o"></i> 229 kvm tar ca 5,5h</li>
                                    <li><i className="fa fa-clock-o"></i> 260 kvm tar ca 6h</li>
                                    <li><i className="fa fa-clock-o"></i> 300 kvm tar ca 7h</li>
                                    <li><i className="fa fa-clock-o"></i> 350 kvm tar ca 8h</li>
                                </ul>
                                <Link to="#" className="btn btn-primary">Beställa nu</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Price;