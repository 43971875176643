import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/logo-white-s3.png';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                   
                            <ul>
                            <a
                                className="navbar-brand"
                                href="/"
                            >


                                <img src={imgOne} alt="img" height="auto" width="auto" />
                            </a>
                                <li><a href='https://www.facebook.com/Molnfria-Hem-AB-100723875963640' className="fa fa-facebook" target="_blank"></a></li>
                                <li><a href='https://www.instagram.com/molnfriahem/' className="fa fa-instagram" target="_blank"></a></li>

                            </ul>
                            <p>Copyright <i className="fa fa-copyright"></i> 2022 Molnfria Hem AB. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;