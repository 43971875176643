import React from 'react';
import 'isomorphic-fetch';


class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.text = '';
            this.setState({ formFields });
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({ formFields });
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({ formFields });
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({ formFields });
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({ formFields });
    }

    onHideSuccess = () => {
        this.setState({ submitted: false });
    }

    successMessage = () => {
        if (this.state.submitted) {
            return (
                <div
                    className="alert alert-success alert-dismissible fade show"
                    style={{ marginTop: '20px', marginBottom: '0' }}
                >
                    <strong>Thank you!</strong> Your message is send to the owner.
                    <button
                        type="button"
                        className="btn-close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }




    render() {
        return (
            <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h4>Kundservice</h4>
                        <h2>Vad kan vi  <span>hjälpa</span>  dig med?</h2>
                        <p>Här når du oss direkt via telefon eller mejl. Kontaktar du oss via mejl svarar vi vanligtvis inom 24 timmar på vardagar.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-12">

                            <iframe
                                title="Google Map"
                                src="https://maps.google.com/maps?q=Ekholmsvägen+206,+Skärholmen,+Sverige&output=embed"
                                width="100%"
                                height="400"
                                loading="lazy"
                            ></iframe>




                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker"></i>Molnfria Hem AB
                                        <br></br> Organisationsnummer: 559368-3054
                                    </li>

                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <a href="mailto:addax@gmail.com">E-mejl: info@molnfriahem.se</a>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <a href="tel:124412-2445515">Telefon: 08-400 220 33</a>
                                    </li>

                                </ul>
                            </div>
                        </div>




 



                     







                   
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form">
                                <h4>Kontakta oss</h4>
                                <form id="contactForm" name="contact" method="post">

                                <input type='hidden' name="form-name" value="contact"></input>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Namn</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="name" 
                                                    id="name" 
                                                    required={true}
                                                    data-error="Please enter your name" 
                                                    value={this.state.formFields.name}
                                                    onChange={this.nameChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">E-Post</label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    required={true}
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={this.emailChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="number">Telefon</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="number" 
                                                    id="number" 
                                                    required={true}
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={this.phoneChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Meddelande</label>
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    id="message" 
                                                    cols="30" 
                                                    rows="4" 
                                                    required={true}
                                                    data-error="Write your message" 
                                                    value={this.state.formFields.text}
                                                    onChange={this.textChangeHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Skicka</button>
                                            {this.successMessage()} 
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>




                </div>
                
            </div>


{/*
            <br></br>
            <form name="contact" method="post" >

                <input type='hidden' name="form-name" value="contact"></input>
          <p>
            <label>Your Name: <input type="text" name="name"/></label>
          </p>
          <p>
            <label>Your Email: <input type="email" name="email"/></label>
          </p>
          <p>
            <label>Message: <textarea name="message"></textarea></label>
          </p>
          <p>
            <button type="submit">Send</button>
          </p>
        </form>,

<br>
</br>
*/}




       
            </section >
        );
    }
}

export default Contact;