import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/sthlm.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Hemstädning i Stockholm <span>och dess närområde</span></h2>
                        <p>Vill du kunna tillbringa mer tid med familj och vänner? Då kan du anlita oss för hemstädning! Vår vision är att kunna erbjuda alla hushåll i Stockholm hemstädning till ett rimligt pris. Dessutom erbjuder vi dig givetvis ett pris med RUT-avdrag! Vi håller tät kontakt med dig som kund för att säkerställa att alla är nöjda.</p>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-addax">
                                <div className="section-title">
                                    <h2>Vi gör hemstädning enkelt, <br></br> smidigt och lättillgängligt</h2>
                                    <p>Våra duktiga städare har lång erfarenhet och arbetar både snabbt och effektivt. På så sätt kan vi erbjuda dig ett lågt pris.

Om du behöver hjälp med städning i Stockholm, då är du välkommen att kontakta oss!</p>
<br></br><br></br>
<h2>Skattereduktion</h2>
<p>Kom ihåg att du som privat kund har rätt till skattereduktion på alla våra tjänster. Det innebär att den är en hushållsnära tjänst (RUT) och att du som kund bara betalar 50% av arbetskostnaden. Skattereduktionen administreras av oss på Molnfria Hem när du får fakturan.

Läs mer om RUT-avdrag: </p>
                                </div>

 

                                <a href="https://www.skatteverket.se/privat/fastigheterochbostad/rotarbeteochrutarbete/safungerarrutavdraget.4.d5e04db14b6fef2c866097.html" className="btn btn-primary">Läsa mer här</a>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                                <div className="video-btn">
                                    <Link 
                                        onClick={e => {e.preventDefault(); this.openModal()}}
                                        to="#" 
                                        className="popup-youtube"
                                    >
                                        <i className="fa fa-play"></i>
                                    </Link>
                                    <ModalVideo 
                                        channel='youtube' 
                                        isOpen={this.state.isOpen} 
                                        videoId='bk7McNUjWgw' 
                                        onClose={() => this.setState({isOpen: false})} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;