import React from 'react'
import BannerTwo from '../PageBanners/BannerTwo';
import Welcome from '../Common/Welcome';
import Strategy from '../Common/Strategy';
import WhyWeDifferent from '../Common/WhyWeDifferent';
import FaqServices from '../Common/FaqServices';
import FaqServicesTwo from '../Common/FaqServicesTwo';
import Price from '../Common/Price';
import Subscribe from '../Common/Subscribe';
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';

class HomeTwo extends React.Component {
    render() {
        return (
            <>
                {/* Main Banner */}
                <BannerTwo />

         {/* Welcome Area */}
                <Welcome />

                {/* Städtjänster*/}
             {/*    <WhyWeDifferent /> */}

                                 {/* Strategy Area */}
                                {/*    <Strategy />  */}


                {/* FAQ Area 1 */}
             {/*      <FaqServices /> */}

                {/* FAQ Area 2 */}
                {/*   <FaqServicesTwo /> */}

                {/* Price Area 
                {/*   <Price />*/}

           

                {/* Subscribe Area */}
                <Subscribe />

                {/* Footer Area */}
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />


            </>
        );
    }
}

export default HomeTwo;