import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

class Villkor extends React.Component {
    render() {
        return (
            <section className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Här kan du läsa om – <span>våra villkor</span></h2>
             
                    </div>

                    <div className="row">



                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx2" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                              Villkor- Detta ingår i vår provstädning
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support2" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <h5>
                                                    <p></p><br></br>
                                                    <p>Bokningsvillkor för provstädning</p><br></br>

<p>Provstädning</p></h5><br></br>
Provstädningen är inte bindande för fortsatt städning, utan bara för det bokade tillfället.
Erbjudandet med 50% rabatt* är bara avsett för dig som eventuellt vill ha återkommande städning,
men först vill prova på.
<br></br><br></br>
<p><h5>Åtagande – Molnfria Hem</h5></p>
<br></br>

Molnfria Hem förbinder sig att utföra överenskomna tjänster på ett professionellt och för
branschen korrekt sätt. Städningen som utförs är enligt följande detaljerad lista. Vid provstädning
är städtiden alltid minst 4 timmar.


<br></br><br></br>
<p><h5>Åtagande – Kund</h5></p>
<br></br>

Du som kund skall tillhandahålla fullt fungerande utrustning och material i enlighet med
specifikation som tillhandahålls av Molnfria Hem för utförande av städtjänsten. För att
underlätta städningen i just ert hem behöver Molnfria Hem svar på några korta frågor som
kommer mejlas ut.


<br></br><br></br>
<p><h5>Avbokning – ombokning</h5></p>
<br></br>

Eventuell avbokning eller ombokning ska göras senast 3 arbetsdagar innan själva städtillfället,
i annat fall är tiden förbrukad och kommer att debiteras.


<br></br><br></br>
<p><h5>Reklamation</h5></p>
<br></br>

Vid klagomål på utförd städning med 50% rabatt*, ges ingen möjlighet till vare sig
åtgärd eller kompensation. Detta på grund av det låga priset. Vid återkommande städning
följer vi förstås konsumentverkets rekommendationer för god kundvård, där vi alltid följer
upp en kunds synpunkter och utför någon form av åtgärd enligt överenskommelse med
kunden.
Reklamation vid ordinarie pris, eller vid skada oavsett pris, ska denna komma företaget
tillhanda senast inom 48 timmar efter att arbetet är utfört. I annat fall tar Molnfria Hem inte på
sig uppgiften att behandla någon reklamation eller kompensation. Alla överenskommelser
utöver dessa villkor ska ske skriftligen eller via mejl.

<br></br><br></br>
<p><h5>Godkännande</h5></p>
<br></br>


Genom att boka en provstädning med rabatterat pris godkänner du bokningsvillkor för en provstädning.

*Rabatten gäller priset för engångsstädning.<br></br><br></br>
* Priserna är efter rutavdrag. För städuppdrag mer än 15 km från T-Centralen tillkommer en
inställelseavgift på 75 kr.
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    
                           


                                </Accordion>
                            </div>
                        </div>
                 

                    </div>
                </div>
            </section>
        );
    }
}

export default  Villkor;