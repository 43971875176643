import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

class FaqServicesTwo extends React.Component {
    render() {
        return (
            <section className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Detta ingår i vår  – <span>Storstädning</span></h2>
                        <p>Detaljerad lista <br></br> De flesta av våra kunder bokar veckostädning för att regelbundet få hemmet rent och snyggt. Ibland är det dock bra att boka in en storstädning för att rengöra på djupet. Genom att boka in en storstädning så kombineras den vanliga veckostädningen med en mer grundlig städning.

                            Här nedan ser du vilka moment som ingår i vår storstädning.</p>
                    </div>

                    <div className="row">



                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx2" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Köket
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support2" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Avtorkning av allt kakel i köket</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av köksluckor både på insidan och på utsidan</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av diskho och kranar</li>
                                                        <li><i className="fa fa-check"></i>Tömning av sophink</li>
                                                        <li><i className="fa fa-check"></i>Städning av det skåp där sophinken förvaras</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Damning/avtorkning av öppna ytor, t.ex. köksbord, köksbänkar, hyllor, eluttag </li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av köksfläkten</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av kylskåpets insida</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av frysens insida (den måste vara tömd och avfrostad)</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av mikrovågsugnens insida </li>
                                                        <li><i className="fa fa-check"></i>Rengöring av ugnens insida </li>
                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Badrummet
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support2" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Rengöring av allt kakel i badrummet</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av toalettstol, duschkabin och badkar (badkaret plockas inte isär)</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av handfatet – även under</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av kranar, duschhållare och nåbara rör</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av badrumsskåpens insida</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet </li>
                                                        <li><i className="fa fa-check"></i>Utvändig dammsugning/avtorkning av badrumsmöbler </li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av skåpluckor på insidan och utsidan </li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av badrumsfläkten </li>
                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Vardagsrum

                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support2" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Dammtorkning/avtorkning av alla öppna ytor, t.ex. bord, byrå, bokhyllor, eluttag</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av soffor och fåtöljer - ovanpå och under dynorna</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av mattor</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av nåbara tavlor och lampor</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>

                                                        <li><i className="fa fa-check"></i>Avtorkning av skåp på höjden (endast nåbar höjd)</li>

                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Sovrum
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support2" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Dammtorkning/avtorkning av alla öppna ytor, t.ex. nattduksbord, byrå, bokhyllor, eluttag</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av mattor</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av garderober på höjden (endast nåbar höjd)</li>
                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                Alla andra rum
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <section id="custom-support2" className="tab-panel">

                                                <div className="why-we-different-text">
                                                    <p>Detta ingår i vår städning</p>
                                                    <ul>
                                                        <li><i className="fa fa-check"></i>Dammtorkning/avtorkning av alla öppna ytor, t.ex. bord, byrå, bokhyllor, eluttag</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av soffor och fåtöljer - ovanpå och under dynorna</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning och våttorkning av golvet</li>
                                                        <li><i className="fa fa-check"></i>Dammsugning av mattor</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av golvlister och socklar</li>
                                                        <li><i className="fa fa-check"></i>Dammtorkning av nåbara lampor</li>
                                                        <li><i className="fa fa-check"></i>Avtorkning av garderober på insidan</li>
                                                        <li><i className="fa fa-check"></i>Rengöring av dörrar och dörrkarmar i alla rum</li>
                                                        <li><i className="fa fa-check"></i>Spegelputsning</li>
                                                        <li><i className="fa fa-check"></i>Dammar av möbler på höjden (endast nåbar höjd)</li>

                                                    </ul>
                                                </div>
                                            </section>
                                        </AccordionItemPanel>
                                    </AccordionItem>


                                </Accordion>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="image2"></div>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default FaqServicesTwo;